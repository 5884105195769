import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Item from "../components/item";

import {
  Breadcrumbs,
  Button,
  Hero,
  IconBox,
  SectionHeader,
} from "../components/common";

import Subscription from "../components/subscription";
import { getStaticSEOImage } from "../helpers";

function HomePage({ pageContext: { data: products }, location }) {
  if (!products?.length) {
    return null;
  }

  const [itemCount, setItemCount] = useState(15);
  const title = "All Products";
  const description =
    "Beautify your content, design, presentation, website with carefully crafted background images. Steal the spotlight of your potential customers and convert them.";

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title={title}
        description={description}
        image={getStaticSEOImage("all-products")}
        path={location?.pathname}
      />

      <section className="md:mt-24 my-12 mb-18 md:mb-32">
        <Hero
          subTitle="Explore Vackground"
          title={title}
          description={description}
        >
          <Breadcrumbs
            lists={[
              {
                title: "Home",
                to: "/",
              },
              {
                title: "All Products",
                to: "/categories",
              },
            ]}
          />
        </Hero>
      </section>
      <section className="md:mb-32 mb-24">
        <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
          {products?.map((product) => (
            <Item
              key={product.id}
              title={product.Name}
              price={product.Price}
              salePrice={product.SalePrice}
              featuredImage={product.FeaturedImage.data.attributes.localFile}
              url={product.URL}
              // fromCategory={{
              //   Name: category.Name,
              //   URL: category.URL,
              // }}
            />
          ))}
        </div>
        {products.length > itemCount && (
          <div className="md:mt-16 mt-12 flex items-center justify-center">
            <Button
              onClick={() => setItemCount(itemCount + 15)}
              text="Load More Products"
              icon="vg-chevron-down"
            />
          </div>
        )}
      </section>
      {/* {subCategories.length && (
        <section className="mb-32">
          <div className="mb-12">
            <SecondaryHeader
              title="Sub Categories"
              description="Categories have too many products to choose from. That’s why we gathered our design assets into niche collections. Go grab your desired asset now, it’s easier than ever."
            />
          </div>
          <div className="grid grid-cols-3 gap-x-8 gap-y-16">
            <CategoryItem />
            <CategoryItem />
            <CategoryItem />
          </div>
        </section>
      )} */}
      <section className="mb-32">
        <div className="md:mb-16 mb-20">
          <SectionHeader
            art
            title={
              <>
                State of The Art Backgrounds
                <br />
                Comes With Benefits
              </>
            }
            description="We aim to give more, to ensure you grow big. Get the best perks for your best money. Use our products unlimited times in unlimited projects by unlimited ways."
          />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-12">
          <IconBox icon="vg-infinity" title="Free Trial Version">
            Use the free version to try before buy or consider it as a freebie!
          </IconBox>
          <IconBox icon="vg-file" title="Unlimited Use">
            Buy once and use it forever. Sounds like A lifetime deal? Yes!
          </IconBox>
          <IconBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-circle-check"
            title="60-Day Refund"
          >
            <>
              Never ever worry while you checkout. Get a piece of mind, for{" "}
              <Link to="/refund">60 days!</Link>
            </>
          </IconBox>
        </div>
      </section>
      {/* <section className="mb-40">
        <div className="mb-16">
          <SectionHeader
            art
            artVector={Discussion}
            title={
              <>
                Read The Reactions!
                <br />
                Our Happy Customers Shoutout
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center gap-x-8">Tweets</div>
      </section> */}
      <section className="mb-16 lg:mt-48 mt-40">
        <Subscription />
      </section>
    </Layout>
  );
}

HomePage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default HomePage;
